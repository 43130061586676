import React from 'react'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import Parallax from '../components/parallax'
import SocialButtons from '../components/footer/socialbuttons'
import { ContactQuery } from '../../types/graphql-types'

interface Props {
  data: ContactQuery
  location: Location
}

const DermalFillers: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  if (meta) {
    meta.title = 'Contact wit Ada Aesthetics'
  }

  return (
    <Layout location={location}>
      <Meta site={meta} />
      <Parallax titleName="Contact" customClass="page_title" />
      <section className="background-contact s-pt-60 s-pt-lg-100 s-pt-xl-150 s-pb-60 s-pb-xl-90 c-mb-20 c-gutter-60">
        <div className="container">
          <div className="row">
            <div className="fw-divider-space hidden-above-lg mt-20"></div>

            <div className="col-lg-7 col-xl-8">
              <h3 className="mt-0 mb-35 text-capitalize">get in touch</h3>
              <h4>
                <a href="mailto:contact@adaaesthetics.uk">
                  contact@adaaesthetics.uk
                </a>
              </h4>
            </div>
            <div className="col-lg-5 col-xl-4" data-animation="scaleAppear">
              <div className="fw-divider-space hidden-above-lg mt-40"></div>
              <h3 className="mt-0 mb-25 text-capitalize">Contact info</h3>
              <div className="media mb-15">
                <h5 className="fs-20 mb-0 min-w-100">Address:</h5>
                <div className="media-body ml-0 d-flex flex-column">
                  <span>15 Ousden Drive, Cheshunt, Waltham Cross, EN8 9RL</span>
                </div>
              </div>
              <div className="media mb-15">
                <h5 className="fs-20 mb-0 min-w-100">Phone:</h5>
                <div className="media-body ml-0 d-flex flex-column">
                  <span>(+44) 07478705252</span>
                </div>
              </div>
              <div className="media mb-20">
                <h5 className="fs-20 mb-30 min-w-100">Email:</h5>
                <div className="media-body ml-0 d-flex flex-column">
                  <span>contact@adaaesthetics.uk</span>
                </div>
              </div>
              <div className="media mb-20">
                <div className="media-body ml-0 d-flex flex-column">
                  <SocialButtons />
                </div>
              </div>
              <h3 className="mt-0 mb-20 text-capitalize">Open Hours</h3>
              <div className="media mb-15">
                <h5 className="fs-20 mb-0 min-w-100">Weekdays:</h5>
                <div className="media-body ml-0 d-flex flex-column">
                  <span>9:00 - 18:00</span>
                </div>
              </div>
              <div className="media mb-15">
                <h5 className="fs-20 mb-0 min-w-100">Saturday:</h5>
                <div className="media-body ml-0 d-flex flex-column">
                  <span>On Request</span>
                </div>
              </div>
              <div className="media mb-15">
                <h5 className="fs-20 mb-0 min-w-100">Sunday:</h5>
                <div className="media-body ml-0 d-flex flex-column">
                  <span>On Request</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="fw-divider-space hidden-below-lg mt-50"></div>
      <div className="fw-divider-space hidden-xs hidden-above-lg mt-20"></div>
    </Layout>
  )
}

export default DermalFillers

export const pageQuery = graphql`
  query Contact {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
