import React from 'react'

interface Props {
  titleName: string
  customClass: string
}

const Parallax: React.FC<Props> = ({ titleName, customClass }: Props) => {
  return (
    <section
      className={`${customClass} s-parallax s-overlay ls title-overlay s-py-25`}
    >
      <div className="container">
        <div className="row">
          <div className="fw-divider-space hidden-below-lg mt-130"></div>
          <div className="fw-divider-space hidden-above-lg mt-60"></div>

          <div className="col-md-12 text-center">
            <h1>{titleName}</h1>
          </div>

          <div className="fw-divider-space hidden-below-lg mt-130"></div>
          <div className="fw-divider-space hidden-above-lg mt-60"></div>
        </div>
      </div>
    </section>
  )
}

export default Parallax;
